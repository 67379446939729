<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{$t('analytics.progress_by_content')}}</b-card-title>
      <b-dropdown
        variant="transparent"
        class="chart-dropdown "
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
      <template #button-content>
        {{$t('analytics.'+period)}}
          <feather-icon
            icon="ChevronDownIcon"
            size="16"
          />
      </template>
        <b-dropdown-item
          v-for="day in periodOptions"
          :key="day"
          @click="period = day"
        >
          {{ $t('analytics.'+day) }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body >
      <template v-if="!loading">
        <b-row v-if="Object.keys(hierarchy).length > 0">
          <b-col cols="12">
            <b-button
              variant="secondary"
              @click="showLeftPanel = !showLeftPanel"
              class="mb-1"
            >
              <feather-icon
                icon="FilterIcon"
                size="12"
              />  
              <span :class="['ml-1', showLeftPanel?'d-block-inline':'d-none']">{{$t('learning_path.filters')}}</span>
            </b-button>
          </b-col>
          <b-col cols="12" md="4" :class="showLeftPanel?'d-block':'d-none'">
            <LeftGraphicsHierarchy :hierarchy="hierarchy" :show="showLeftPanel" @contentSelected="selectContent"/>
          </b-col>
          <b-col cols="12" :md="showLeftPanel?'8':'12'">
            <GraficoProgresoContenido v-if="selected_content" 
              :content_id="selected_content"
              :dreamer="dreamer"
              :start_date="start_date"
              :end_date="end_date"
              />
            <p v-else class="text-center">{{$t('analytics.select a content')}}</p>
          </b-col>
        </b-row>
        <NoDataChartPlaceholderVue v-else />
      </template>
      <div v-else class="d-flex justify-content-center mb-1">
        <b-spinner label="Loading..." />
      </div>

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BSpinner,BButton,
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, 
  BCardBody,
} from 'bootstrap-vue'
import { getPlayedContentsHierarchy } from '@/api/routes'
import LeftGraphicsHierarchy from './LeftGraphicsHierarchy.vue'
import GraficoProgresoContenido from './GraficoProgresoContenido.vue'
import NoDataChartPlaceholderVue from '@/views/sl-components/NoDataChartPlaceholder.vue'
export default {
  components: {
    BRow, BCol, BSpinner, BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,

    LeftGraphicsHierarchy,
    GraficoProgresoContenido,
    NoDataChartPlaceholderVue
  },
  props:{
    dreamer: {
      type: Object,
      required: true,
    }
  },
  data() {
    let hoy = new Date();
    let date = new Date()
    date.setFullYear(date.getFullYear() - 1);
    let start = date

    return {
      showLeftPanel: true,
      periodOptions:['week', 'month', 'year'],
      period: 'year',
      start_date: start,
      end_date: hoy,

      loading: true,
      hierarchy: {},

      selected_content:null

    }
  },
  created() {
    this.getData()
  },

  watch: {
    '$i18n.locale': {
      handler() {
        this.getData();
      },
      immediate: true
    },
    period(){
      if(this.period == 'year'){
        let date = new Date()
        date.setFullYear(date.getFullYear() - 1);
        this.start_date = date
      }
      if(this.period == 'month'){
        let date = new Date()
        date.setMonth(date.getMonth() - 1);
        this.start_date = date
      }
      if(this.period == 'week'){
        let date = new Date()
        date.setDate(date.getDate() - 7);
        this.start_date = date
      }

      this.getData()

    },
    currentBP(new_val){
      if(new_val == 'md' || new_val == 'lg' || new_val == 'xl'){
        this.showLeftPanel = true
      }
    }
  },
  computed:{
    currentBP(){
      return this.$store.getters['app/currentBreakPoint']
    }
  },
  methods:{
    selectContent(content_id){
      console.log(this.$store.getters['app/currentBreakPoint'], "breakpoint")
      if(this.$store.getters['app/currentBreakPoint'] === 'xs' || 
         this.$store.getters['app/currentBreakPoint'] === 'sm'){
        this.showLeftPanel = false
      }
      this.selected_content = content_id
    },
     async getData (){
      this.loading = true
      let data = {
        'dreamer_id': this.dreamer.id,
        'start_date': this.start_date.toISOString().substring(0,10),
        'end_date': this.end_date.toISOString().substring(0,10)
      } 
      await this.$http.post(getPlayedContentsHierarchy, data).then(response => {
        console.log("getPlayedContentsHierarchy", response.data.data)
        if(Object.keys(response.data.data).length > 0){
          this.hierarchy = response.data.data
        } else {
          this.hierarchy = {}
        }
        this.loading = false
      }).catch(error => {
        console.log("Err -> ", error)
      }) 
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
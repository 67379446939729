<template>
<div class="d-flex align-center justify-content-center ma-4">
  <div class="d-none d-sm-block position-relative box">
    <b-img :src="require('@/assets/images/placeholders/no_data_chart.png')" fluid alt="Responsive image" />
    <h4 class="text-center font-weight-bolder top-center text-secondary">No data</h4>
  </div>
  <div class="d-block d-sm-none">
    <h4 class="text-center font-weight-bolder text-secondary">No data</h4>
  </div>

</div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  }
}
</script>
<style scoped>
.top-center{
  position: absolute;
  top: 45%;
  left: 40%;
}
.box{
  padding: 2px;
  background-color: whitesmoke;
  border: 2px solid lightgray;
  border-radius: 10px;
}
</style>
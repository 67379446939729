<template>
    <div v-if="!loading">
      <!-- BREADCRUMBS -->
      <custom-breadcrumb :pageTitle="dreamer.name" :breadcrumb="breadcrumb"></custom-breadcrumb>

      <b-row>
        <!-- ANALÍTICAS DE USO -->
        <!-- <b-col cols="12" class="mb-1">
          <h2 class="mb-2">{{$t('analytics.usage')}}</h2>
          <ContenidosMasUtilizados :dreamer="dreamer"/>
        </b-col> -->
        <b-col cols="12">
          <h2 class="mb-2">{{$t('analytics.progress')}}</h2>
          <GraficosDeProgreso :dreamer="dreamer"/>
        </b-col>
        <b-col cols="12" >

            <b-card
              :img-src="require('@/assets/images/logo/backgroundLA.png')"
              img-alt="Profile Cover Photo"
              img-top
              class="card-profile"
            >
              <div class="profile-image-wrapper">
                <div class="profile-image p-1">
                  <b-img
                    size="114"
                    variant="light-secondary"
                    :src="require('@/assets/images/logo/logo-footer.png')"
                  />
                </div>
              </div>
              <h3 class="mt-2">
                {{$t('analytics.lp_evaluation')}}
              </h3>
              <h6 class="text-muted">
                {{$t('Coming Soon')}}...
              </h6>
            </b-card>

        </b-col>

      </b-row>
    </div>
    <div v-else class="d-flex justify-content-center mb-1">
      <b-spinner label="Loading..." />
    </div>
</template>
  
<script>
  import {  getDreamer } from '@/api/routes'
  import ContenidosMasUtilizados from './components/dreamer-charts/ContenidosMasUtilizados.vue'
  import GraficosDeProgreso from './components/GraficosDeProgreso.vue'
  import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

  import {
    BSpinner,
    BCol,
    BRow,
    BCard,
    BImg
  } from 'bootstrap-vue'
  
  
  export default {
    components: {
      BSpinner,
      BCol,
      BRow,
      BCard,
      BImg,
  
      CustomBreadcrumb,
      ContenidosMasUtilizados,
      GraficosDeProgreso, 
    },
    data() {  
      return {
        loading: true,
        dreamer: {},
      }
    },
    mounted() {
      this.getData()
    },
    computed: {
      breadcrumb() {
        return [
          {
            text: this.$t('Dreamers'),
            to: 'dreamers'
          },
          {
            text: this.dreamer.name,
            to: 'dreamer-view',
            params: {
                  id: this.dreamer.id,
                },
          },
          {
            text: this.$t('analytics.detailed_analytics'),
            active: true,
          },
        ]
      },
    },
    watch: {
      '$i18n.locale': {
        handler: function () {
          // console.log('Current locale:', this.$i18n.locale); 
          this.getData();
        },
      }
    },
    methods: {
      async getData() {
        await this.$http.get(getDreamer + '/' + this.$route.params.id).then(response => {
          if (response.data.status == 200) {
            this.dreamer = response.data.dreamer;
            this.loading = false
          } else {
            this.makeToast('danger', this.$t('Error'), this.$t('message.' + response.data.msg_code));
            this.loading = false
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.loading = false
          this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
        })
      },
      makeToast(variant = null, tit = '', msg = '') {
        this.$bvToast.toast(msg, {
          title: tit,
          variant,
          solid: false,
        })
      },
    }
  }
  
  </script>
  
<template>
  <!-- LINE CHART -->
  <div>
    <vue-apex-charts v-if="!loading"
      type="bar"
      height="400"
      :options="chart.chartOptions"
      :series="chart.series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components:{
    VueApexCharts
  },
  props:{
    chartData: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      loading: true,
      chart:{}
    }
  },
  created(){
    this.parseData()
  },
  methods:{
    parseData(){
      this.$nextTick(() => {
        this.loading = true

        let origin_data = this.chartData.data

        /* Obtenemos las partidas y stages totales del feedback devuelto */
        let partidas = origin_data.reduce(function (r, a) {
                r[a.partida] = r[a.partida] || [];
                r[a.partida].push(a);
                return r;
            }, Object.create(null));
        
        let stages = origin_data.reduce(function (r, a) {
            r[a.stage_id] = r[a.stage_id] || [];
            r[a.stage_id].push(a);
            return r;
        }, Object.create(null));
        let max_stages =  Math.max.apply(Math, Object.keys(stages));
        /* Creamos la matriz */
        let allstages = [];
        for(var i=0; i<max_stages; i++) {
            allstages[i] = [];
            for(var j=0; j<Object.keys(partidas).length; j++) {
                allstages[i][j] = 0;
            }
        }

        /* Rellenamos la matriz y los labels con la duracion de los datos que tengamos */
        let my_labels = [] //dates
        origin_data.map( d => {
          my_labels[d.partida-1] = new Date(d.date).toLocaleDateString('es')
          allstages[d.stage_id-1][d.partida-1] = d.duration
        }) 

        /* Creamos las series a partir de la matriz */
        let mySeries = []
        allstages.map((s,idx) => {
          mySeries.push({
              name: (idx+1)+'º', // Stage/pantalla
              data: s,
          })
        })

        this.chart =  {
          series: mySeries,
          chartOptions: {
            chart: {
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                columnWidth: '50%',
                colors: {
                  backgroundBarColors: [],
                  backgroundBarRadius: 5,
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: true,
              position: 'bottom',
              fontSize: '14px',
              fontFamily: 'Montserrat',
              horizontalAlign: 'left',
              width: 100
            },
            stroke: {
              show: true,
              colors: ['transparent'],
            },
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              categories: my_labels,
            },
            yaxis: {
              // opposite: isRtl,
            },
            fill: {
              opacity: 1,
            },
          },
        }
        this.loading =false
      });
      
    }
  }
}
</script>

<style>

</style>
<template>
  <!-- LINE CHART -->
  <div>
    <vue-apex-charts v-if="!loading"
      type="bar"
      height="400"
      :options="chart.chartOptions"
      :series="chart.series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components:{
    VueApexCharts
  },
  props:{
    chartData: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      loading: true,
      chart:{}
    }
  },
  created(){
    this.parseData()
  },
  methods:{
    parseData(){
      this.$nextTick(() => {
      this.loading = true
      let data_langs = [] //langs
      let my_labels = [] //dates
      let my_data_won = [] //stage_id = 1
      let my_data_lost = [] //stage_id = 2
      let my_data_tied = [] //stage_id = 3
      let my_level_won = [] //stage_id = 1
      let my_level_lost = [] //stage_id = 2
      let my_level_tied = [] //stage_id = 3
      this.chartData.data.map(d => {
          let oponents = {
            1: 'apprentice',
            2: 'intermediate',
            3: 'advanced',
            4: 'master',
            null: 'person'
          }

          my_labels.push(new Date(d.date).toLocaleDateString('es'))
          if(d.stage_id == 1) {
            my_data_won.push(d.duration)
            my_data_lost.push(0)
            my_data_tied.push(0)

            my_level_won.push(this.$t('apps_levels.'+oponents[d.level_id]))
            my_level_lost.push(0)
            my_level_tied.push(0)
          }
          if(d.stage_id == 2) {
            my_data_won.push(0)
            my_data_lost.push(d.duration)
            my_data_tied.push(0)

            my_level_won.push(0)
            my_level_lost.push(this.$t('apps_levels.'+oponents[d.level_id]))
            my_level_tied.push(0)
          }
          if(d.stage_id == 3) {
            my_data_won.push(0)
            my_data_lost.push(0)
            my_data_tied.push(d.duration)

            my_level_won.push(0)
            my_level_lost.push(0)
            my_level_tied.push(this.$t('apps_levels.'+oponents[d.level_id]))
          }
  
          data_langs.push(this.$t('lang.'+d.lang.name))

      })
      this.chart =  {
        series: [
          {
            name: this.$t('analytics.won'),
            data: my_data_won,
            level: my_level_won,
          },
          {
            name: this.$t('analytics.lost'),
            data: my_data_lost,
            level: my_level_lost,
          },
          {
            name: this.$t('analytics.tied'),
            data: my_data_tied,
            level: my_level_tied,
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: [$themeColors.success, $themeColors.danger, '#eee'],
          plotOptions: {
            bar: {
              columnWidth: '50%',
              colors: {
                backgroundBarColors: [],
                backgroundBarRadius: 5,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              //seriesIndex : 0 = ganado, 1 = perdido, 2 = empate
              //dataPointIndex : index de la fecha
              return `<div class="arrow_box">
                <span><b>${w.config.series[seriesIndex].name}</b>: ${series[seriesIndex][dataPointIndex]} s</span>
                <br>
                <span>${w.config.series[seriesIndex].level[dataPointIndex]} </span>
                <br>
                <span>${data_langs[dataPointIndex]}</span>
              </div>`
            }
          },
          xaxis: {
            categories: my_labels,
          },
          yaxis: {
            // opposite: isRtl,
            labels: {
                show: true,
                align: 'right',
                minWidth: 0,
                maxWidth: 160,
                
                formatter: (value) => { return value +' s'},
            },
          },
          fill: {
            opacity: 1,
          },
        },
      }
      this.loading =false
      });
      
    }
  }
}
</script>

<style>
.arrow_box{
  padding: 20px;
}
</style>
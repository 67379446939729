<template>
  <!-- LINE CHART -->
  <div>
    <vue-apex-charts v-if="!loading"
        type="line"
        height="400"
        :options="chart.chartOptions"
        :series="chart.series"
      />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'


export default {
  components:{
    VueApexCharts
  },
  props:{
    chartData: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      loading: true,
      chart:{}
    }
  },
  created(){
    this.parseData()
  },
  methods:{
    parseData(){
      this.$nextTick(() => {
      this.loading = true
      let data_langs = [] //langs
      let my_labels = [] //dates
      let my_data = [] //duration
      this.chartData.data.map(d => {
        my_labels.push(new Date(d.date).toLocaleDateString('es'))
        let score = parseFloat(d.score)
        let percentage = 0
        if(this.chartData.formula == '(score/(score+failures))*100'){
          percentage = (score+d.failures) == 0 ? 0 : (score/(score+d.failures))*100
        } else {
          percentage = total_score==0 ? 0 : (score/(d.total_score))*100
        }
        my_data.push(Math.round(percentage))
        data_langs.push(this.$t('lang.'+d.lang.name))
      })
      this.chart =  {
        series: [
          {
            data: my_data
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            size: 5,
            colors: $themeColors.success,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
          },
          colors: [$themeColors.danger],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            lineCap:'round',
            colors:[$themeColors.primary]
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 pt-1"><span>'}
              </span>
                <ul class="px-1">
                  <li>
                    ${data_langs[data.dataPointIndex]}
                  </li>
                  <li>
                    ${data.series[data.seriesIndex][data.dataPointIndex]} %
                  </li>
                </ul>
              </div>`
            },
          },
          xaxis: {
            categories: my_labels,
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      }
      this.loading =false
      });
      
    }
  }
}
</script>

<style>

</style>
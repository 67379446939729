<template>
  <div>
    <h5 class="mt-3">{{resolveTitle}}</h5>
    <b-table 
      responsive 
      hover  
      v-if="chartData.data.length>0"
      style="border-radius: 8px"
      class="border-primary"
      :sticky-header="true"
      :no-border-collapse="true"
      :fields="fields"
      :items="chartData.data">
      
      <template #cell(result)="data">
        <feather-icon   
          :icon="data.value == 'won' ? 'SmileIcon' : data.value == 'lost' ? 'FrownIcon' : 'MehIcon'" 
          :stroke="data.value == 'won' ? 'green' : data.value == 'lost' ? 'red' :'grey'" 
          class="mr-50" />
        <span>{{$t('analytics.'+data.value)}}</span>
      </template>

      <template #cell(completed)="data">
        <feather-icon  
          :icon="data.value ==1?'CheckCircleIcon':'CircleIcon'" 
          :stroke="data.value?'green':'grey'" 
          class="mr-25" />
      </template>

    </b-table>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable
  },
 
  props:{
    chartData: {
      type: Object,
      required: true
    },
    content_type:{
      type: String,
      required: true
    }
  },
  data(){
    return {
      fields: [],
    }
  },
  mounted(){
    let basic_fields = [
      { key: 'date', label: this.$t('customize.date'), 
        sortable: true,
        class: 'dateWidth',
        formatter: value => new Date(value).toLocaleDateString('es')+' '+new Date(value).toTimeString().slice(0,5),
      },
      { key: 'duration', label: this.$t('analytics.usage_time'),
        formatter: value => this.humanReadableTime(value)
        },
      { key: 'level_id', label: this.$t('analytics.level'), 
        sortable:true,
        formatter: value => this.$t('apps_levels.'+this.resolveLevel(value))
      },
      { key: 'lang', label: this.$t('language'), 
        sortable:true,
        formatter: value => value.name ? this.$t('lang.'+value.name) : ''
      }
    ]
    
    let moreFields = []
    switch (this.chartData.format) {
      case 'successline':
        if(this.chartData.formula == '(score/(score+failures))*100'){
          moreFields.push({ key: 'score', label: this.$t('analytics.graphs.format.successline'), 
            formatter: (value, key, item) => `${(parseInt(item.score)+item.failures) == 0 ?'0':Math.round((parseInt(item.score)/(parseInt(item.score)+item.failures))*100)}%` 
          })
        } else {
          moreFields.push({ key: 'score', label: this.$t('analytics.graphs.format.successline'), 
            formatter: (value, key, item) => `${total_score == 0?'0':Math.round((parseInt(item.score)/item.total_score)*100)}%` 
          })
        }
        break;
      case 'quizzbar':
        moreFields.push({ key: 'score', label: this.$t('analytics.score'), 
          formatter: (value, key, item) => `${parseInt(item.score)}/${item.total_score}` 
        })
        break;
      case 'stagebar':
        moreFields.push({ key: 'score', label: this.$t('analytics.score'), 
          formatter: (value, key, item) => `${parseInt(item.score)}/${item.total_score}` 
        })
        moreFields.push({ key: 'stage', label: this.$t('analytics.stage'), 
          formatter: (value, key, item) => `${item.stage_id?item.stage_id:'-'}` 
        })
        break;
      case 'outcomebar':
        let result = ['won', 'lost', 'tied']
        moreFields.push({ key: 'result', label: this.$t('analytics.score'), 
          formatter: (value, key, item) => `${item.stage_id?result[item.stage_id-1]:'lost'}` 
        })
        break;
      case 'scaperoom':
        moreFields.push({ key: 'stage', label: this.$t('analytics.stage'), 
          formatter: (value, key, item) => `${item.stage_id}` 
        })
        moreFields.push({ key: 'partida', label: this.$t('analytics.game'), 
          formatter: (value) => `#${value}` 
        })
        break;
      default:
        break;
    }
    
    if(this.chartData.show_incomplete == 1){
      moreFields.push( { key: 'completed', label: this.$t('analytics.completed') })
    }
    let allFields = basic_fields.concat(moreFields) 
    if(this.content_type == 'pdf'){
      allFields.filter(f => f.key != 'duration')
    }
    this.fields = allFields
  },
  computed: {
    resolveTitle(){
      //en escape room puedo poner nivel y lang
      if(this.chartData.format == 'scaperoom'){
        let format = this.$t('analytics.graphs.format.'+this.chartData.format)
        let level = this.$t('apps_levels.'+this.resolveLevel(this.chartData.level_id))
        let lang = this.chartData.lang_name ? ' - '+this.$t('lang.'+this.chartData.lang_name) : ''
        
        return  `${format} (${level}${lang})`
      }
      return this.$t('analytics.graphs.format.'+this.chartData.format)
    }
  },
  methods:{  
    resolveLevel(level_id){
      switch (level_id) {
        case 1:
          return 'apprentice'
        case 2:
          return 'intermediate'
        case 3:
          return 'advanced'
        case 4:
          return 'master'      
        default:
          return 'person'      
      }
    }
  }
}
</script>

<style>
.dateWidth{
    min-width: 180px !important;
}
</style>
<template>
  <div class="py-1">
    <div class="left-hierarchy">
    <app-collapse accordion v-if="show" type="border">
      <app-collapse-item :title="cat[0]=='intelligences'?$t('Categories'):$t('analytics.'+cat[0])" v-for="cat in Object.entries(hierarchy)" :key="cat[0]">
          <app-collapse accordion type="border">
            <app-collapse-item :title="$t('analytics.graphs.'+cat[0]+'.'+subcat[0])" v-for="subcat in Object.entries(cat[1])" :key="subcat[0]">
                <b-list-group >
                  <b-list-group-item 
                    :variant="content.id == content_selected?'primary':''"
                    v-for="content in subcat[1]" :key="content.id"
                    button
                    @click="handleClick(content)">
                    <span :title="content.name" class="d-flex justify-start align-start text-truncate">
                      <b-img
                        :src="content.browerImg"
                        blank-color="#ccc"
                        width="30"
                        alt="placeholder"
                        class="mr-1 p-25"
                        :style="{borderRadius: '5px' }"
                      />
                      {{content.name}}
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </app-collapse-item>
          </app-collapse>
        </app-collapse-item>
    </app-collapse>
    </div>
  </div>
</template>

<script>
import { BButton, BMedia, BImg, BCardText, BListGroup, BListGroupItem } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'


export default {
  components: {
    BButton, 
    BMedia,
    BImg,
    BCardText,
    BListGroup, BListGroupItem,

    AppCollapse,
    AppCollapseItem,

  },
  props:{
    hierarchy:{
      type: Object,
      required: true
    },
    show:{
      type: Boolean,
      required: true
    }
  },
  data(){
    return {
      content_selected: null
    }
  },
  methods: {
    handleClick(content) {
      this.content_selected = content.id
      this.$emit('contentSelected', content.id);
    }
  }
}
</script>

<style>
.justify-start{justify-content: flex-start;}
.align-start{ align-items: flex-start}

.left-hierarchy{
  max-height: 500px;
  overflow-x: scroll;
}
</style>
<template>
  <!-- LINE CHART -->
  <div>
    <vue-apex-charts v-if="!loading"
      type="bar"
      height="400"
      :options="chart.chartOptions"
      :series="chart.series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components:{
    VueApexCharts
  },
  props:{
    chartData: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      loading: true,
      chart:{}
    }
  },
  created(){
    this.parseData()
  },
  methods:{
    parseData(){
      this.$nextTick(() => {
      this.loading = true
      let data_langs = [] //langs
      let my_labels = [] //dates
      let my_data_score = [] //score
      let my_data_failures = [] //failures
      let my_data_incomplete = [] //incomplete
      this.chartData.data.map(d => {
          my_labels.push(new Date(d.date).toLocaleDateString('es'))
          my_data_score.push(parseInt(d.score))
          my_data_failures.push(d.failures)
          my_data_incomplete.push(d.total_score - parseInt(d.score) - d.failures)
          data_langs.push(this.$t('lang.'+d.lang.name))
      })
      this.chart =  {
        series: [
          {
            name: this.$t('analytics.correct'), //score
            data: my_data_score,
          },
          {
            name: this.$t('analytics.failures'),
            data: my_data_failures,
          },
          {
            name: this.$t('analytics.incomplete'),
            data: my_data_incomplete,
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: [$themeColors.success, $themeColors.danger, '#eee'],
          plotOptions: {
            bar: {
              columnWidth: '50%',
              colors: {
                backgroundBarColors: [],
                backgroundBarRadius: 5,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            position: 'top',
            fontSize: '14px',
            fontFamily: 'Montserrat',
            horizontalAlign: 'left',
          },
          stroke: {
            show: true,
            colors: ['transparent'],
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              //seriesIndex : 0 = ganado, 1 = perdido, 2 = empate
              //dataPointIndex : index de la fecha
              return `<div class="arrow_box">
                <span><b>${w.config.series[seriesIndex].name}</b>: ${series[seriesIndex][dataPointIndex]}</span>
                <br>
                <span>${data_langs[dataPointIndex]}</span>
              </div>`
            }
          },
          xaxis: {
            categories: my_labels,
          },
          yaxis: {
            // opposite: isRtl,
          },
          fill: {
            opacity: 1,
          },
        },
      }
      this.loading =false
      });
      
    }
  }
}
</script>

<style>

</style>
<template>
  <!-- LINE CHART -->
  <div>
    <vue-apex-charts v-if="!loading"
        type="line"
        height="400"
        :options="chart.chartOptions"
        :series="chart.series"
      />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'


export default {
  components:{
    VueApexCharts
  },
  props:{
    chartData: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      loading: true,
      chart:{}
    }
  },
  created(){
    this.parseData()
  },
  methods:{
    parseData(){
      
      this.$nextTick(() => {
      this.loading = true
      let data_langs = [] //langs
      let my_labels = [] //dates
      let my_level = [] //duration
      let my_correct = [] //score
      let my_failures = [] //score
      let my_incomplete = [] //score
      this.chartData.data.map(d => {
        data_langs.push(this.$t('lang.'+d.lang.name))
        my_labels.push(new Date(d.date).toLocaleDateString('es'))
        my_level.push(parseFloat(d.stage_id))
        my_correct.push(d.score)
        my_failures.push(d.failures)
        my_incomplete.push(d.total_score - (parseInt(d.score) + d.failures))
      })
      this.chart =  {
        
        series: [
          {
            name: this.$t('analytics.level'),
            data: my_level,
            type: 'line'
          },
          {
            name: 'Score',
            data: my_correct,
            type: 'column'
          },
          {
            name: 'Failures',
            data: my_failures,
            type: 'column'
          },
          {
            name: 'Incomplete',
            data: my_incomplete,
            type: 'column'
          },
        ],
        
        chartOptions: {
          translations: {
            score: this.$t('analytics.activity'),
            correct: this.$t('analytics.correct'),
            failures: this.$t('analytics.failures'),
            incomplete: this.$t('analytics.incomplete'),
            level: this.$t('analytics.level'),
          },
          chart: {
            height: 350,
            type: "line",
            stacked: true,
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            size: 5,
            colors: $themeColors.primary,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
          },
          colors: [$themeColors.secondary, $themeColors.success, $themeColors.danger, '#eee'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            lineCap:'round',
            width: [5, 0, 0, 0]
            //colors:[$themeColors.primary]
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            shared: false,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              return `<div class="px-1 py-50"><span>${w.config.translations.level}: ${
                series[0][dataPointIndex]
              }</span>
              <ul class="px-1">
                <li>
                  ${w.config.translations.correct}: ${series[1][dataPointIndex]}
                </li>
                <li>
                  ${w.config.translations.failures}: ${series[2][dataPointIndex]}
                </li>
                <li>
                  ${w.config.translations.incomplete}: ${series[3][dataPointIndex]}
                </li>
              </ul>
              <span>
                ${data_langs[dataPointIndex]}
              </span>
              </div>`
            },
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: my_labels,
          },
          yaxis: [
            { 
              min: 0,
              stepSize:1,
              title: {
                text: this.$t('analytics.level'),
              },
            },
            {
              min: 0,
              stepSize:1,
              opposite: true,
              title: {
                text: this.$t('analytics.activity'),
              }
            },
            {
              min: 0,
              stepSize:1,
              opposite: true,
              title: {
                text: this.$t('analytics.activity'),
              },
              show: false
            },
            {
              min: 0,
              stepSize:1,
              opposite: true,
              title: {
                text: this.$t('analytics.incomplete'),
              },
              show: false
            }
          ],
        },
      }
      this.loading =false
      });
      
    }
  }
}
</script>

<style>

</style>
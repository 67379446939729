<template>
  <div>
    <template v-if="!loading">
      <div
        class="d-flex justify-content-start align-items-center mb-1"
      >
        <b-avatar
          rounded
          variant="light"
          :src="content_info.img"
          size="50"
          class="mr-1"
        />
        <div class="profile-user-info">
          <h6 class="mb-0">
            {{ content_info.name }}
          </h6>
          <small class="text-muted">{{ $t('content_types.'+content_info.type) }}</small>
        </div>
      </div>
      <b-tabs v-if="graficos.length >0">

        <b-tab :title="$t('analytics.activity')">
          <template v-for="(grafico, idx) in graficos">
            <ActivityTable  
                :key="'t'+idx"
                v-if="grafico.data"
                :chartData="grafico" 
                :content_type="content_info.type"/>
          </template>
        </b-tab>
        <template v-for="(grafico, idx) in graficos" >
          <template v-if="( grafico.format =='timeline' ||
                            grafico.format =='videoline')  && graphicHasData(grafico.format, grafico.data) > 1">
            <b-tab :title="$t('analytics.graphs.format.'+grafico.format)" :key="idx">
                <LineChart  :chartData="grafico" />
            </b-tab>
          </template>
          <template  v-else-if="grafico.format == 'quizzbar'">
            <b-tab :title="$t('analytics.graphs.format.'+grafico.format)" :key="idx">
              <QuizBarChart  :chartData="grafico" />
            </b-tab>
          </template>
          <template  v-else-if="grafico.format == 'outcomebar'">
            <b-tab :title="$t('analytics.graphs.format.'+grafico.format)" :key="idx">
              <OutcomeBarChart  :chartData="grafico" />
            </b-tab>
          </template>
          <template v-else-if="( grafico.format =='successline') && graphicHasData(grafico.format, grafico.data) > 1 ">
            <b-tab :title="$t('analytics.graphs.format.'+grafico.format)" :key="idx">
              <SuccessLineChart  :chartData="grafico" />
            </b-tab>
          </template>
          <template v-if="grafico.format =='stagebar' && graphicHasData(grafico.format, grafico.data) > 1 ">
            <b-tab :title="$t('analytics.graphs.format.'+grafico.format)" :key="idx">
                <AdaptiveLineChart  :chartData="grafico" />
            </b-tab>
          </template>

          <!-- Pendiente -->
          <template  v-else-if="grafico.format == 'scaperoom'">
            <b-tab v-if="grafico.data && grafico.data.length>0" 
              :title="$t('analytics.graphs.format.'+grafico.format)+' ('+$t('apps_levels.'+resolveLevel(grafico.level_id))+' - '+ $t('lang.'+grafico.lang_name)+')'" 
              :key="idx">
                <ScapeBarChart  :chartData="grafico" />
            </b-tab>
          </template>
        </template>
       </b-tabs>
       <small v-else class="text-muted">
        [ERR:418]
        <!-- NO HAY GRAFICOS ASOCIADOS A ESTE CONTENIDO -->
       </small>
    </template>
    <div v-else class="d-flex justify-content-center mb-1">
      <b-spinner label="Loading..." />
    </div>

  </div>
</template>

<script>
import { getPlayedContentsFeedback } from '@/api/routes'

import ScapeBarChart from './dreamer-charts/ScapeBarChart.vue'
import QuizBarChart from './dreamer-charts/QuizBarChart.vue'
import OutcomeBarChart from './dreamer-charts/OutcomeBarChart.vue'
import ActivityTable from './dreamer-charts/ActivityTable.vue'

import LineChart from './dreamer-charts/LineChart.vue'
import SuccessLineChart from './dreamer-charts/SuccessLineChart.vue'
import AdaptiveLineChart from './dreamer-charts/AdaptiveLineChart.vue'
import {
  BTabs, BTab, BAvatar, BSpinner,
} from 'bootstrap-vue'
export default {
  components:{
    BTabs, BTab, BAvatar, BSpinner,

    LineChart,
    ScapeBarChart,
    QuizBarChart,
    ActivityTable,
    OutcomeBarChart,
    SuccessLineChart,
    AdaptiveLineChart
  },
  props:{
     dreamer: {
      type: Object,
      required: true,
    },
    content_id:{
      type: [Number, String],
      required: true
    },
    start_date:{
      type: Date,
      required: true
    },
    end_date:{
      type: Date,
      required: true
    }
  },
  data(){
    return {
      loading: true,
      content_info:{},
      graficos:[]
    }
  },
  mounted(){
    this.getData()
  },
  watch:{
    content_id(){
      this.getData()
    }
  },
  methods:{
    resolveLevel(level_id){
      switch (level_id) {
        case 1:
          return 'apprentice'
        case 2:
          return 'intermediate'
        case 3:
          return 'advanced'
        case 4:
          return 'master'      
        default:
          return 'person'      
      }
    },
    graphicHasData(format, graphic){
      let count = 0
      if(format == 'videoline' || format == 'timeline'){
        graphic.map(d => {
          if(parseInt(d.duration)>0){
            count +=1
          }
        })
      } else if(format == 'successline'){
        if (graphic.length >1){
          let has_useful_data = false
          graphic.map(d => {
            if(parseInt(d.score)>0){
              has_useful_data = true
            }
          })
          if(has_useful_data){
            count  = graphic.length
          }
        }
      } else {
        graphic.map(d => {
          if(parseInt(d.score)>0){
            count +=1
          }
        })
      }


      return count
    },
    async getData(){
      this.loading = true
      let data = {
        'dreamer_id': this.dreamer.id,
        'content_id': this.content_id,
        'start_date': this.start_date.toISOString().substring(0,10),
        'end_date': this.end_date.toISOString().substring(0,10)
      } 
      await this.$http.post(getPlayedContentsFeedback, data).then(response => {
        console.log("getPlayedContentsFeedback", response.data.data)

        this.content_info = {
          id: response.data.data.id,
          name: response.data.data.name,
          type: response.data.data.type,
          img: response.data.data.browerImg,
          usage: response.data.data.usage,
        }
        
        let content_graphs = []
        if(response.data.data.type == "escape_room"){
          /* Verificar por idioma */
          Object.keys(response.data.data.feedbacks).map(lang => {
            console.log(lang) //es pt
            let this_lang_name = response.data.data.feedbacks[lang].name
            response.data.data.feedbacks[lang].levels.map( g => {
              if(g.format){
                g['lang_name'] = this_lang_name
                content_graphs.push(g)
              }
            })
          })

        } else {
          response.data.data.feedbacks.map(g => {
            if(g.format){
              content_graphs.push(g)
            }
          })
        }
        
        this.graficos = content_graphs

        this.loading = false
      }).catch(error => {
        console.log("Err -> ", error)
      })
    }
  }
}
</script>

<style>

</style>